import * as types from "../../components/shared/constants";

//zet de default theme 0 default andere nummer indien meer thema aanwezig is
export default function reducer(state = { currentTheme: 0 }, actions) {
    switch (actions.type) {
        case types.SET_THEME:
            return {
                ...state,
                currentTheme: actions.payload,
            };

        default:
            return state;
    }
}
