import { SUCCESS, UPSERT_LICENSES, RESET_LICENSES } from "../constants";
import { getSearchOption } from "../../utilities/common";
import { createLicenseVersion } from "./licenseVersionsActions";
import { updateModules } from "./modulesActions";
import { updateLicenseType } from "./licenseTypesActions";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

let search = window.location.search.substring(window.location.search.indexOf("=") + 1, window.location.search.length);
// haal alle licenties op
export const fetchLicenses = (searchString, option) => async (dispatch) => {
    try {
        const response = await api.get("licenses/licenses", {
            SearchString: searchString,
            Top: 10000,
            SearchFilter: option,
        });

        if (response.data.value.length !== 0) {
            dispatch({
                type: RESET_LICENSES,
            });
            dispatch({
                type: UPSERT_LICENSES,
                payload: response.data.value,
            });
        } else {
            dispatch({
                type: RESET_LICENSES,
            });
        }
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentie toevoegen/verwijderen
export const createUpdateLicenses = (license, version, apps, details, history) => async (dispatch) => {
    let keuze = license.keuze;
    delete license["keuze"];
    let params = {
        ...license,
    };
    let defSearch;

    if (search === "empty") {
        defSearch = "";
    } else {
        defSearch = search;
    }

    try {
        const response = await api.post(`licenses/licenses`, params);

        // Bij het wijzigen van een licentie toon succesmelding
        if (keuze === "reset licentie" || keuze === "edit licentie") {
            dispatch({
                type: SUCCESS,
                payload: "Licentie gewijzigd " + response.data.value,
            });
        }
        // haal alle licenties opnieuw op
        dispatch(fetchLicenses(defSearch, Number(getSearchOption())));
        // dispatch(fetchLicensees(defSearch, Number(getSearchOption())));

        if (apps.length !== undefined) {
            dispatch(handleTypes(apps, response.data.value));
        }

        dispatch(
            handleDetails(details, version, keuze, response.data.value, apps, {
                licenseesid: license.licenseesid,
                licdescription: license.description,
                history: history,
            }),
        );
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

const handleTypes = (apps, lic) => async (dispatch) => {
    // updaten/toevoegen van de licentie app types

    await apps.forEach((apptype) => {
        if (apptype !== null) {
            dispatch(
                updateLicenseType({
                    licensesid: lic,
                    deflictypesid: Number(apptype.substring(apptype.indexOf(",") + 1, apptype.length)),
                }),
            );
        }
    });
};

const handleDetails = (details, version, keuze, lic, apps, naarLic) => async (dispatch) => {
    // updaten/toevoegen van de licentie app details
    await details.forEach((element, index) => {
        if (
            element !== null &&
            // backoffice uitfilteren
            element.name !== "BAC_USE" &&
            element.name !== "BAC_LAP"
        ) {
            dispatch(
                updateModules(
                    {
                        licensesid: lic,
                        code: element.name,
                        value: element.name === "RIT_SIZ" ? 0 : element.value,
                        defoptionsid: element.name === "RIT_SIZ" ? element.value : 0,
                        activationdate: "",
                        exptestdate: "",
                        expdate: "",
                    },
                    {},
                ),
            );
        }

        if (index === details.length - 1) {
            // setTimeout(() => {
            dispatch(handleVersion(version, keuze, lic, apps, naarLic));
            //}, 3000);
        }
    });
};

const handleVersion =
    // eslint-disable-next-line require-await
    (version, keuze, lic, apps, naarLic) => async (dispatch) => {
        // alleen bij nieuwe licentie, versie & apps toevoegen
        if (keuze === "licentie") {
            if (version.licensesid !== undefined) {
                dispatch(createLicenseVersion({ ...version, licensesid: lic }, apps));
                // ga naar licentie details
                naarLic.history.push(`/license_details/${naarLic.licenseesid}/${naarLic.licdescription}/${lic}`);
            }
        }
    };

// licentie verwijderen
export const deleteLicenses = (licensesid, deleted) => async (dispatch) => {
    try {
        await api.delete(`licenses/licenses`, {
            licensesid: licensesid,
            permanently: deleted,
        });

        let defSearch;
        if (search === "empty") {
            defSearch = "";
        } else {
            defSearch = search;
        }
        // haal alle licenties op met search optie
        dispatch(fetchLicenses(defSearch, Number(getSearchOption())));
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Licentie verwijderd " + licensesid,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

export const fetchDatabases = () => async (dispatch) => {
    try {
        const response = await api.get("licenses/databases", null);
        return response.data.databases;
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
