import { Model, attr } from "redux-orm";
import { CREATE_LICENSES, UPDATE_LICENSES, DELETE_LICENSES, UPSERT_LICENSES, RESET_LICENSES } from "../constants";

class Licenses extends Model {
    static reducer(action, licenses, session) {
        switch (action.type) {
            case CREATE_LICENSES:
                licenses.create(action.payload);
                break;
            case UPSERT_LICENSES:
                action.payload.forEach((element) => {
                    licenses.upsert(element);
                });
                break;
            case UPDATE_LICENSES:
                licenses.withId(action.payload.licenseesid).update(action.payload);
                break;
            case DELETE_LICENSES:
                const license = licenses.withId(action.payload);
                license.delete();
                break;
            case RESET_LICENSES:
                licenses.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Licenses.modelName = "Licenses";

Licenses.options = {
    idAttribute: "licenseesid", // default idd
};
// attributen
Licenses.fields = {
    $id: attr(),
    licenseesid: attr(),
    customercode: attr(),
    description: attr(),
    licenses: attr(),
};

export default Licenses;
