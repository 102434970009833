import { ORM } from "redux-orm";
import Licensees from "./licenseesModel";
import Licenses from "./licensesModel";
import Licversions from "./licversionsModel";
import Modules from "./modulesModel";
import Defapps from "./defappsModel";
import Defmodulegroups from "./defmodulegroupsModel";
import Defversiontypes from "./defversiontypesModel";
import Admins from "./adminsModel";
import LicenseModules from "./licenseModulesModel";
import LicenseTypes from "./licenseTypesModel";
import Defoptions from "./defoptionsModel";

const orm = new ORM({
  stateSelector: (state) => state.scheme,
});
orm.register(
  Licensees,
  Licenses,
  Licversions,
  Modules,
  Defapps,
  Defmodulegroups,
  Defversiontypes,
  Admins,
  LicenseModules,
  LicenseTypes,
  Defoptions
);

export default orm;
