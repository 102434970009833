import { SUCCESS } from "../constants";
import { fetchLicenseVersions } from "./licenseVersionsActions";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

// licapps toevoegen/wijzigen
export const updateLicapps = (apps) => async (dispatch) => {
    try {
        await api.post(`licenses/licapps`, apps);
        // haal alle versies bij specifieke licentie op
        dispatch(fetchLicenseVersions(apps.licensesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licapps verwijderen
export const deleteLicapps = (id, license) => async (dispatch) => {
    try {
        const deleteResponse = await api.delete(`licenses/licapps`, {
            licappsid: id,
            permanently: true,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Licapp verwijderd" + deleteResponse.data.value,
        });
        // haal alle versies bij specifieke licentie op
        dispatch(fetchLicenseVersions(license));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
