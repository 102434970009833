import { Model, attr } from "redux-orm";
import { CREATE_DEFAPPS, UPDATE_DEFAPPS, DELETE_DEFAPPS, UPSERT_DEFAPPS, RESET_DEFAPPS } from "../constants";

class Defapps extends Model {
    static reducer(action, Defapps, session) {
        switch (action.type) {
            case CREATE_DEFAPPS:
                Defapps.create(action.payload);
                break;
            case UPSERT_DEFAPPS:
                action.payload.forEach((element) => {
                    Defapps.upsert(element);
                });

                break;
            case UPDATE_DEFAPPS:
                Defapps.withId(action.payload.defappsid).update(action.payload);
                break;
            case DELETE_DEFAPPS:
                const defapps = Defapps.withId(action.payload);
                defapps.delete();
                break;
            case RESET_DEFAPPS:
                Defapps.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Defapps.modelName = "Defapps";

Defapps.options = {
    idAttribute: "defappsid", // default idd
};
// attributen
Defapps.fields = {
    $id: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
};

export default Defapps;
