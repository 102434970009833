import { RESET_LICENSETYPES, UPSERT_LICENSETYPES } from "../constants";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

// haal alle licentie type op
export const fetchLicenseTypes = (licensesid) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/lictypes`, {
            licensesid: licensesid,
        });

        dispatch({
            type: RESET_LICENSETYPES,
            payload: response.data.value,
        });

        dispatch({
            type: UPSERT_LICENSETYPES,
            payload: response.data.value,
            licensesid: Number(licensesid),
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentie type wijzigen/toevoegen
export const updateLicenseType = (type) => async (dispatch) => {
    try {
        await api.post(`licenses/lictypes`, type);
        // // toon succesmelding
        // dispatch({
        //     type: SUCCESS,
        //     payload: " update licensetype" + updateResponse.data.value,
        // });
        // haal alle type bij specifieke licentie op
        dispatch(fetchLicenseTypes(type.licensesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
