import { Model, attr } from "redux-orm";
import { CREATE_MODULES, UPDATE_MODULES, DELETE_MODULES, UPSERT_MODULES, RESET_MODULES } from "../constants";

class Modules extends Model {
    static reducer(action, Modules, session) {
        switch (action.type) {
            case CREATE_MODULES:
                Modules.create(action.payload);
                break;
            case UPSERT_MODULES:
                action.payload.forEach((module) => {
                    Modules.upsert({
                        ...module,
                        uid: Number(String(module.licappsid) + String(module.defmodulesid)),
                        licversionid: action.licversionid,
                    });
                });
                break;
            case UPDATE_MODULES:
                Modules.withId(action.payload.uid).update(action.payload);
                break;
            case DELETE_MODULES:
                const modules = Modules.withId(action.payload);
                modules.delete();
                break;
            case RESET_MODULES:
                Modules.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Modules.modelName = "Modules";

Modules.options = {
    //idAttribute: "licappsid" + "-" + "defmodulesid", // default idd
    idAttribute: "uid", // default idd ==> Number(String(modules.licappsid) + String(modules.defmodulesid))
};
// attributen
Modules.fields = {
    uid: attr(),
    $id: attr(),
    activationdate: attr(),
    code: attr(),
    defmodulesid: attr(),
    defoptionsid: attr(),
    expdate: attr(),
    exptestdate: attr(),
    licappsid: attr(),
    licensesid: attr(),
    licversionsid: attr(),
    value: attr(),
};

export default Modules;
