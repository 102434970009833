import { ERROR } from "../../redux/constants";
import { TableCell } from "@material-ui/core";

/*de naam zegt het al: er zit een bug in MUIDataTable. Door deze functie aan te roepen in een component
 *dat de datatable gebruikt ben je van de rode lettertjes in de console af.
 */
export function MUIDatatableIsEmptyBugFix() {
    const oldRender = TableCell.render;

    return (TableCell.render = function (...args) {
        const [props, ...otherArgs] = args;
        if (typeof props === "object" && props && "isEmpty" in props) {
            const { isEmpty, ...propsWithoutEmpty } = props;
            return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
        } else {
            return oldRender.apply(this, args);
        }
    });
}

function randomBetween(a, b) {
    const max = Math.max(a, b);
    const min = Math.min(a, b);
    return Math.floor(Math.random() * (max - min) + min);
}

// Get an integer hashCode for the given String
function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash;
}

// Get a random function for the given Integer seed
// https://stackoverflow.com/a/47593316/15469537
function mulberry32(seed) {
    return function () {
        let t = (seed += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
}

export function randomColor(firstColor, secondColor, seed = null) {
    const first = firstColor.toUpperCase().substring(1, secondColor.length);
    const second = secondColor.toUpperCase().substring(1, firstColor.length);
    const scale = "0123456789ABCDEF";
    let color = "#";

    // Seeded random function, for consistent random colors after reloads etc.
    let randomGenerator;
    if (seed) {
        randomGenerator = mulberry32(hashCode(seed));
    }

    for (let i = 0; i < first.length && i < second.length; i++) {
        const random = randomBetween(scale.indexOf(first[i]), scale.indexOf(second[i]), randomGenerator);
        color += scale[random];
    }
    return color;
}

//dag van vandaag
const dag = (new Date().getDate() < 10 ? "0" : "") + new Date().getDate();
const month = (new Date().getMonth() + 1 < 10 ? "0" : "") + (new Date().getMonth() + 1);
const year = new Date().getFullYear();
export const today = [year, month, dag].join("-");

//converteer datum van 'Tue Sep 08 2020 10:37:57 GMT+0200 (Midden-Europese zomertijd)' naar '2020-09-08'
export function convertDate(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

export const handleErrors = (error) => (dispatch) => {
    const status = error?.response?.status;

    if (error.response) {
        /*
         * Bepaal welke melding getoond moet worden indien response is aanwezig
         * Toon de error melding van de response. Dit wordt straks uitgebreid met translation obv response.code
         */
        if (status !== 401) {
            dispatch({
                type: ERROR,
                payload: error.response?.data?.error?.message || error.response?.data,
            });
        }
    } else {
        dispatch({ type: ERROR, payload: error.message });
    }
};

export const getAppIdByCode = (apps, code) => apps?.find((app) => app.code === code)?.defappsid;

export const getDetailsByCode = (apps, code) => apps?.find((app) => app.code === code)?.value;
