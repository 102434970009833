import { Model, attr } from "redux-orm";
import {
    CREATE_DEFOPTIONS,
    UPDATE_DEFOPTIONS,
    DELETE_DEFOPTIONS,
    UPSERT_DEFOPTIONS,
    RESET_DEFOPTIONS,
} from "../constants";

class Defoptions extends Model {
    static reducer(action, Defoptions, session) {
        switch (action.type) {
            case CREATE_DEFOPTIONS:
                Defoptions.create(action.payload);
                break;
            case UPSERT_DEFOPTIONS:
                action.payload.forEach((option) => {
                    Defoptions.upsert(option);
                });
                break;
            case UPDATE_DEFOPTIONS:
                Defoptions.withId(action.payload.defoptionsid).update(action.payload);
                break;
            case DELETE_DEFOPTIONS:
                const option = Defoptions.withId(action.payload);
                option.delete();
                break;
            case RESET_DEFOPTIONS:
                Defoptions.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Defoptions.modelName = "Defoptions";

Defoptions.options = {
    idAttribute: "defoptionsid", // default idd
};
// attributen
Defoptions.fields = {
    $id: attr(),
    defoptionsid: attr(),
    defmodulesid: attr(),
    code: attr(),
    description: attr(),
    value: attr(),
};

export default Defoptions;
