import { CREATE_DEFOPTIONS, DELETE_DEFOPTIONS, UPDATE_DEFOPTIONS, SUCCESS, UPSERT_DEFOPTIONS } from "../constants";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

// haal alle defoptions op
export const fetchDefoptions = (id) => async (dispatch) => {
    try {
        const response = await api.get(`definitions/defoptions`, {
            defmodulesid: id,
        });

        dispatch({
            type: UPSERT_DEFOPTIONS,
            payload: response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// defoptions toevoegen
export const createDefoptions = (options) => async (dispatch) => {
    try {
        const createResponse = await api.post(`definitions/defoptions`, options);
        const fetchResponse = await api.get(`definitions/defoptions`, {
            defmodulesid: options.defmodulesid,
        });

        const newOption = fetchResponse.data.value.find((option) => option.defoptionsid === createResponse.data.value);

        dispatch({
            type: CREATE_DEFOPTIONS,
            payload: newOption,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Defoption toegevoegd " + createResponse.data.value,
        });
        //dispatch(fetchDefoptions(options.defmodulesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// defoptions wijzigen
export const updateDefoptions = (options) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/defoptions`, options);
        const fetchResponse = await api.get(`definitions/defoptions`, {
            defmodulesid: options.defmodulesid,
        });

        const updateOption = fetchResponse.data.value.find(
            (option) => option.defmodulesid === updateResponse.data.value,
        );

        dispatch({
            type: UPDATE_DEFOPTIONS,
            payload: updateOption,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " Defoption gewijzigd " + updateResponse.data.value,
        });
        //dispatch(fetchDefoptions(options.defmodulesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// defoptions verwijderen
export const deleteDefoptions = (id) => async (dispatch) => {
    try {
        const response = await api.delete(`definitions/defoptions`, {
            defoptionsid: id,
        });
        dispatch({
            type: DELETE_DEFOPTIONS,
            payload: Number(id),
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " Defoption verwijderd " + response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
