import { Model, attr } from "redux-orm";
import { CREATE_ADMIN, UPDATE_ADMIN, DELETE_ADMIN, UPSERT_ADMIN, RESET_ADMIN } from "../constants";

class Admin extends Model {
    static reducer(action, Admin, session) {
        switch (action.type) {
            case CREATE_ADMIN:
                Admin.create(action.payload);
                break;
            case UPSERT_ADMIN:
                Admin.upsert(action.payload);
                break;
            case UPDATE_ADMIN:
                Admin.withId(action.payload.$id).update(action.payload);
                break;
            case DELETE_ADMIN:
                const admins = Admin.withId(action.payload);
                admins.delete();
                break;
            case RESET_ADMIN:
                Admin.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Admin.modelName = "Admin";

Admin.options = {
    idAttribute: "$id", // default idd
};
// attributen
Admin.fields = {
    $id: attr(),
    usersid: attr(),
    loginemail: attr(),
    firstname: attr(),
    infixname: attr(),
    lastname: attr(),
    evoit: attr(),
    emailverified: attr(),
    expdate: attr(),
    lastlogin: attr(),
    deleted: attr(),
    licensesid: attr(),
};

export default Admin;
