import { getBaseURL } from "../redux/actions";
import { getRefreshToken } from "../utilities/common";
import { useAuth } from "./AuthProvider";
import axios from "axios";

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const backend = await getBaseURL("backend");
        const response = await axios.post(`${backend}refresh`, null, {
            headers: { Authorization: `Bearer ${auth.refresh_token ?? getRefreshToken()}` },
        });
        // opslaan van de nieuwe token
        setAuth((prev) => {
            return { ...prev, ...response.data };
        });
        
        sessionStorage.setItem("app_oauth", JSON.stringify({refresh_token:response.data.refresh_token}));

        return response.data;
    };

    return refresh;
};

export default useRefreshToken;
