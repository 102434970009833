import { Model, attr } from "redux-orm";
import {
    UPDATE_LICENSEMODULES,
    DELETE_LICENSEMODULES,
    UPSERT_LICENSEMODULES,
    RESET_LICENSEMODULES,
} from "../constants";

class LicenseModules extends Model {
    static reducer(action, Modules, session) {
        switch (action.type) {
            case UPSERT_LICENSEMODULES:
                action.payload.forEach((module) => {
                    Modules.upsert({
                        ...module,
                        licensesid: action.licensesid,
                        uid: Number(`${action.licensesid}${module.defmodulesid}`),
                    });
                });

                break;
            case UPDATE_LICENSEMODULES:
                Modules.withId(action.payload.uid).update(action.payload);
                break;
            case DELETE_LICENSEMODULES:
                const modules = Modules.withId(action.payload);
                modules.delete();
                break;
            case RESET_LICENSEMODULES:
                Modules.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
LicenseModules.modelName = "LicenseModules";

LicenseModules.options = {
    //idAttribute: "licappsid" + "-" + "defmodulesid", // default idd
    idAttribute: "uid", // default idd
};
// attributen
LicenseModules.fields = {
    uid: attr(),
    $id: attr(),
    code: attr(),
    activationdate: attr(),
    defmodulesid: attr(),
    defoptionsid: attr(),
    expdate: attr(),
    exptestdate: attr(),
    licappsid: attr(),
    licensesid: attr(),
    value: attr(),
};

export default LicenseModules;
