import { combineReducers } from "redux";
import snackbarReducer from "./snackbarReducers";
import themeReducer from "./themeReducers";
import { createReducer } from "redux-orm";
import scheme from "../models/orm";

const schemeReducer = createReducer(scheme);
const clearSchemeReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        return scheme.getEmptyState();
    } else {
        return schemeReducer(state, action);
    }
};
export default combineReducers({
    themeReducer,
    snackbarReducer,
    scheme: clearSchemeReducer,
});
