import {
    SUCCESS,
    CREATE_DEFMODULEGROUPS,
    UPDATE_DEFMODULEGROUPS,
    DELETE_DEFMODULEGROUPS,
    UPSERT_DEFMODULEGROUPS,
} from "../constants";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

// haal de defmodulegroups op
export const fetchDefmodulegroups = (defappsid) => async (dispatch) => {
    try {
        const param = defappsid ? { defappsid: defappsid } : {};

        const response = await api.get(`definitions/defmodulegroups`, param);

        dispatch({
            type: UPSERT_DEFMODULEGROUPS,
            payload: response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// toevoegen van een nieuw defmodulegroup
export const createDefmodulegroups = (modulegroup) => async (dispatch) => {
    try {
        const createResponse = await api.post(`definitions/defmodulegroups`, modulegroup);

        const fetchResponse = await api.get(`definitions/defmodulegroups`, {
            defappsid: modulegroup.defappsid,
        });

        fetchResponse.data.value
            .filter((x) => x.defmodulegroupsid === createResponse.data.value)
            .forEach((module) => {
                dispatch({
                    type: CREATE_DEFMODULEGROUPS,
                    payload: module,
                });
            });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Groep toegevoegd " + createResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// defmodulegroup wijzigen
export const updateDefmodulegroups = (modulegroup) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/defmodulegroups`, modulegroup);

        const fetchResponse = await api.get(`definitions/defmodulegroups`, {
            defappsid: modulegroup.defappsid,
        });

        fetchResponse.data.value
            .filter((x) => x.defmodulegroupsid === updateResponse.data.value)
            .forEach((module) => {
                dispatch({
                    type: UPDATE_DEFMODULEGROUPS,
                    payload: module,
                });
            });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Groep gewijzigd " + updateResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// verwijderd defmodulegroup
export const deleteDefmodulegroups = (id) => async (dispatch) => {
    try {
        await api.delete(`definitions/defmodulegroups`, { defmodulegroupsid: id });

        // verwijderd alleen de geselecteerde module in de orm
        dispatch({
            type: DELETE_DEFMODULEGROUPS,
            payload: Number(id),
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Groep verwijderd " + id,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
