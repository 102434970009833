import styled from "styled-components";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { getToken } from "../../utilities/common";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(6)}px;
    text-align: center;
    background: transparent;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)}px;
    }
`;

function Page404() {
    return (
        <Wrapper>
            <Helmet title="404" />
            <img src="/static/logo-evo-it-404-scala-bold-grijs.png" className="App-logo" alt="404 logo" />
            {/*<Typography component="h2" variant="h1" align="center" gutterBottom >
        404
  </Typography>*/}
            <Typography component="h2" variant="h5" align="center" gutterBottom>
                Pagina niet gevonden.
            </Typography>
            <Typography component="h2" variant="body1" align="center" gutterBottom>
                De pagina die u zoekt bestaat niet of is mogelijk verwijderd.
            </Typography>

            {/* geen token aanwezig redirect naar login anders naar de versie */}
            {getToken() !== null ? (
                <Button component={Link} to="/licenses" variant="contained" color="secondary" mt={2}>
                    Terug naar home
                </Button>
            ) : (
                <Button component={Link} to="/" variant="contained" color="secondary" mt={2}>
                    Terug naar home
                </Button>
            )}
        </Wrapper>
    );
}

export default Page404;
