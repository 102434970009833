import { green, grey } from "@material-ui/core/colors";
import {
  colorGreen,
  colorRed,
  mainColor,
  secondaryColor,
} from "../components/shared/constants";
// const mainColor = "#8C9AA9";
// const secondaryColor = "#870000";
const evoitVariant = {
  name: "Dark",
  palette: {
    primary: {
      main: mainColor,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ffb74d",
      contrastText: "#FFFFFF",
    },
    succes: {
      main: colorGreen,
      contrastText: "#FFFFFF",
    },
    error: {
      main: colorRed,
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#64b5f6",
      contrastText: "#FFFFFF",
    },
  },
  header: {
    color: mainColor,
    //color:"#FFFFFF",
    background: "#FFFFFF",
    search: {
      color: mainColor,
    },
    indicator: {
      background: mainColor,
    },
  },
  sidebar: {
    color: "#FFFFFF",
    background: mainColor,

    header: {
      color: grey[200],
      background: mainColor,
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[200],
      background: mainColor,
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 500,
    },
    badge: {
      color: "#FFFFFF",
      background: "#FFFFFF",
    },
  },
  body: {
    background: "#fafafa",
  },
};

const variants = [evoitVariant];

export default variants;
