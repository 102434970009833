import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { rgba, darken } from "polished";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";
import {
    Chip,
    Collapse,
    Drawer as MuiDrawer,
    IconButton as MuiIconButton,
    Grid,
    Link as LinkBrand,
    List as MuiList,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, ExitToApp } from "@material-ui/icons";
import routes from "../../routes/routes";
import useLogout from "../../auth/useLogout";

//logo navigatie
const LogoApb = styled.img`
    border-radius: 50%;
`;

// eslint-disable-next-line react/display-name
const NavLink = forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;

const Scrollbar = styled(PerfectScrollbar)`
    background-color: ${(props) => props.theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
    background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;

    padding-left: ${(props) => props.theme.spacing(4)}px;
    padding-right: ${(props) => props.theme.spacing(3)}px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        min-height: 155px;
    }
`;

const BrandIcon = styled(LogoApb)`
    margin-right: ${(props) => props.theme.spacing(2)}px;
    color: ${(props) => props.theme.sidebar.header.brand.color};
`;

// .svg--> opacity: 0.5 sidebar icoon verwijderd;
const Category = styled(ListItem)`
    //padding-top: ${(props) => props.theme.spacing(3)}px;
    //padding-bottom: ${(props) => props.theme.spacing(3)}px;
    //padding-left: ${(props) => props.theme.spacing(6)}px;
    //padding-right: ${(props) => props.theme.spacing(5)}px;
    //font-weight: ${(props) => props.theme.typography.fontWeightRegular};

    svg {
        color: ${(props) => props.theme.sidebar.color};
        font-size: 20px;
        width: 25px;
        height: auto;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }

    &.${(props) => props.activeClassName} {
        background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};
        border-style: solid;
        border-color: ${"transparent"};
        border-left-color: ${(props) => props.theme.sidebar.color};
        //border-right-color:  ${"#FFDF00"};
        //border-left-color:  ${"#FFDF00"};
        span {
            //color: ${"#FFDF00"};
            color: ${(props) => props.theme.sidebar.color};
        }
        svg {
            //color: ${"#FFDF00"};
            color: ${(props) => props.theme.sidebar.color};
        }
    }
`;

const CategoryText = styled(ListItemText)`
    //margin-right : auto;
    //margin-left: ${(props) => props.theme.spacing(3)}px;
    span {
        color: ${(props) => props.theme.sidebar.color};
        font-size: ${(props) => props.theme.typography.body1.fontSize}px;
        font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
        //padding: 0 ${(props) => props.theme.spacing(4)}px;
    }
`;

const CategoryIconLess = styled(ExpandLess)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-top: ${(props) => props.theme.spacing(2)}px;
    padding-bottom: ${(props) => props.theme.spacing(2)}px;

    span {
        color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
    }

    &:hover span {
        color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
    }

    &.${(props) => props.activeClassName} {
        background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

        span {
            color: ${(props) => props.theme.sidebar.color};
        }
    }
`;

const LinkText = styled(ListItemText)`
    color: ${(props) => props.theme.sidebar.color};
    span {
        font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    }
    margin-top: 0;
    margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
    background: ${(props) => props.theme.sidebar.badge.background};

    span.MuiChip-label,
    span.MuiChip-label:hover {
        cursor: pointer;
        color: ${(props) => props.theme.sidebar.badge.color};
        padding-left: ${(props) => props.theme.spacing(2)}px;
        padding-right: ${(props) => props.theme.spacing(2)}px;
    }
`;

const CategoryBadge = styled(LinkBadge)`
    top: 12px;
`;

const SidebarSection = styled(Typography)`
    color: ${(props) => props.theme.sidebar.color};
    padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(6)}px
        ${(props) => props.theme.spacing(1)}px;
    opacity: 0.9;
    display: block;
`;

const SidebarFooter = styled.div`
    background-color: ${(props) => props.theme.sidebar.footer.background} !important;
    padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
        color: white;
        margin-right: 5px;
        margin-left: 5px;
    }
    color: white;
    font-size: 18px;
`;

function SidebarCategory({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) {
    return (
        <Category {...rest}>
            <Grid container direction="column" justifyContent="space-around" alignItems="center">
                <Grid item xs>
                    {icon}
                </Grid>
                <Grid item xs>
                    <CategoryText>{name}</CategoryText>
                </Grid>
            </Grid>

            {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
            {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
    );
}

function SidebarLink({ name, to, badge }) {
    return (
        <Link button dense component={NavLink} exact to={to} activeClassName="active">
            <LinkText>{name}</LinkText>
            {badge ? <LinkBadge label={badge} /> : ""}
        </Link>
    );
}

function Sidebar({ classes, staticContext, location, resetStore, ...rest }) {
    const initOpenRoutes = () => {
        /* Open collapse element that matches current url */
        const pathName = location.pathname;

        let _routes = {};

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/";

            _routes = Object.assign({}, _routes, {
                [index]: isActive || isOpen || isHome,
            });
        });

        return _routes;
    };
    // logout & reset redux store
    const logout = useLogout();

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

    const toggle = (index) => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            (item) =>
                openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false })),
        );

        // Toggle selected element
        setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
    };

    return (
        <Drawer variant="permanent" {...rest}>
            {/*<Brand style={{background: "linear-gradient(to bottom, #6d0019 18%,#6d0019 18%,#a51c1c 100%)"}} > */}

            <Brand>
                <LinkBrand href="/">
                    <BrandIcon style={{ width: "90px" }} src="/static/Licentiebeheer.svg" alt="Logo Licentiebeheer" />
                </LinkBrand>
                {/* <BrandIcon style={{filter: "drop-shadow(0 0 0.40rem #8f0222)", width:"100px"}} src="/static/Licentiebeheer.svg" alt="Logo Licentiebeheer" /> */}
            </Brand>

            <Scrollbar>
                <List disablePadding>
                    <Items>
                        {routes.map((category, index) => (
                            <React.Fragment key={index}>
                                {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                                {category.children ? (
                                    <React.Fragment key={index}>
                                        <SidebarCategory
                                            isOpen={!openRoutes[index]}
                                            isCollapsable={true}
                                            name={category.id}
                                            icon={category.icon}
                                            button={true}
                                            onClick={() => toggle(index)}
                                        />

                                        <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                                            {category.children.map((route, index) => (
                                                <SidebarLink
                                                    key={index}
                                                    name={route.name}
                                                    to={route.path}
                                                    icon={route.icon}
                                                    badge={route.badge}
                                                />
                                            ))}
                                        </Collapse>
                                    </React.Fragment>
                                ) : (
                                    <SidebarCategory
                                        isCollapsable={false}
                                        name={category.id}
                                        to={category.path}
                                        activeClassName="active"
                                        component={NavLink}
                                        icon={category.icon}
                                        exact
                                        badge={category.badge}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Items>
                </List>
            </Scrollbar>
            <SidebarFooter>
                {/*<SidebarFooter style={{background: "linear-gradient(to bottom, #a51c1c 21%,#8f0222 56%,#6d0019 95%,#6d0019 100%)"}}>*/}
                <Grid container justifyContent="space-evenly">
                    {/*  <Grid item>
            <StyledBadge

              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              {/*<Avatar>user.name.CharAt(0)</Avatar>
            <StyledAvatar ><Typography variant="h4">{user[0].charAt(0)+ user[2].charAt(0)}</Typography></StyledAvatar>
            </StyledBadge>
          </Grid>*/}

                    <Grid item>
                        {/*<IconButton onClick={handleLogout} > <LogOut style={{height: "35px", width: "35px"}} /></IconButton> */}
                        <IconButton onClick={logout}>
                            <ExitToApp style={{ height: "30px", width: "30px" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
}

export default withRouter(Sidebar);
