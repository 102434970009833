import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useState } from "react";
import { getBaseURL } from "../redux/actions";
import { mainColor } from "../components/shared/constants";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../redux/constants";

function RenewSession() {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    const handleRenew = async () => {
        const protserver = await getBaseURL("frontend");

        setOpen(false);
        dispatch({
            type: LOGOUT,
        });
        if (sessionStorage.getItem("app_user_licenses")) {
            const lic = sessionStorage.getItem("app_user_license");
            // const ver = sessionStorage.getItem("app_user_version");
            sessionStorage.clear();
            window.location.href = `${protserver}app/ELM/${lic}/0`;
        } else {
            window.location.href = `${protserver}`;
        }
    };

    return (
        <Dialog id="sessionDialog" maxWidth="sm" open={open}>
            <DialogTitle
                style={{
                    backgroundColor: mainColor,
                    color: "white",
                }}
            >
                Sessie Beëindigd!
            </DialogTitle>
            <DialogContent dividers>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <Info color="primary" fontSize="large" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography>
                            Er is een time-out opgetreden vanwege inactiviteit of een verlopen sessie. Log opnieuw in om
                            verder te gaan.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={() => handleRenew()}>
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RenewSession;
