import { getBaseURL } from "../redux/actions/authActions";

// return the token from the session storage
export const getToken = () => {
  try {
    const oauth = JSON?.parse(sessionStorage.getItem("app_oauth"));
    return oauth?.access_token || null;
  } catch (error) {
    return null;
  }
};

// return the refresh token from the session storage
export const getRefreshToken = () => {
  try {
    const oauth = JSON?.parse(sessionStorage.getItem("app_oauth"));
    return oauth?.refresh_token || null;
  } catch (error) {
    return null;
  }
};

// return the searchbutton optionid from the session storage
export const getSearchOption = () => {
  return sessionStorage.getItem("searchOption") || 0;
};

// remove the token from the session storage
export const removeToken = () => {
  sessionStorage.removeItem("token");
};

// update the token from the session storage
export const setToken = (utoken) => {
  sessionStorage.setItem("app_oauth", utoken);
};

// set the searchbutton option id from the session storage
export const setSearchOption = (id) => {
  sessionStorage.setItem("searchOption", id);
};

export const getBuildVersion = () => {
  return process.env.REACT_APP_REVISION || "development 1.0";
};
export async function getProtserver() {
  const protserver = await getBaseURL();
  return protserver;
}

export const getURL = (endpoint) => {
  return `${process.env.REACT_APP_API_URL || localhostUrl}${endpoint}/`;
};

export const localhostUrl = `http://${
  process.env.REACT_APP_LOCALIPADDRESS || "localhost"
}:9998/licserver/`;

export const localhostToken = {
  access_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTk2MTk2NjQsIm5iZiI6MTY1OTYxOTYxNCwiZXhwIjoxNjYzMjE5NjY0LCJpc3MiOiIiLCJzdWIiOiJ7REFCNjlBOTYtM0FCMC00ODAwLThCN0MtNTg3NkZFOEI2NkE2fSIsInR5cCI6IkJlYXJlciIsImF1ZCI6IkVMTSIsInNpZCI6MTM5Miwic2NvcGUiOiIiLCJ1c2VyaWQiOjI3OCwibGljZW5zZXNpZCI6MTc0LCJldm9pdCI6dHJ1ZX0.IWAVb9oFdO1H7JV1LZgFE6UX7PafPUkkiA2xxpLR634",
  refresh_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTk2MTk2NjQsIm5iZiI6MTY1OTYxOTYxNCwiZXhwIjoxNjkxMDY5MjY0LCJpc3MiOiIiLCJzdWIiOiJ7REFCNjlBOTYtM0FCMC00ODAwLThCN0MtNTg3NkZFOEI2NkE2fSIsInR5cCI6IlJlZnJlc2giLCJzaWQiOjEzOTIsImF1ZCI6IlBSVCIsInNjb3BlIjoicmVmcmVzaCAiLCJ1c2VyaWQiOjI3OCwibGljZW5zZXNpZCI6MTc0fQ.CvfwWGzVo31O8MRXPNaUD-R3KqeD1ptFpKmNlXYDBY0",
  expires_in: 3600,
};
