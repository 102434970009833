import { Model, attr } from "redux-orm";
import { CREATE_LICENSEES, UPDATE_LICENSEES, DELETE_LICENSEES, UPSERT_LICENSEES, RESET_LICENSEES } from "../constants";

class Licensees extends Model {
    static reducer(action, licensees, session) {
        switch (action.type) {
            case CREATE_LICENSEES:
                licensees.create(action.payload);
                break;
            case UPSERT_LICENSEES:
                action.payload.forEach((element) => {
                    licensees.upsert(element);
                });
                break;
            case UPDATE_LICENSEES:
                licensees.withId(action.payload.licenseesid).update(action.payload);
                break;
            case DELETE_LICENSEES:
                const licensee = licensees.withId(action.payload);
                licensee.delete();
                break;
            case RESET_LICENSEES:
                licensees.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Licensees.modelName = "Licensees";

Licensees.options = {
    idAttribute: "licenseesid", // default idd
};
// attributen
Licensees.fields = {
    $id: attr(),
    licenseesid: attr(),
    code: attr(),
    description: attr(),
    deleted: attr(),
};

export default Licensees;
