import Routes from "./routes/index";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import Helmet from "react-helmet";
import Snackbars from "./components/shared/SnackbarMelding";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import "./App.css";
import maTheme from "./theme";
import { useAuth } from "./auth/AuthProvider";
import UseInterceptors from "./api/useInterceptors";
import useRefreshToken from "./auth/useRefreshToken";
import AuthLayout from "./layouts/Auth";
import RenewSession from "./auth/RenewSession";
import { getRefreshToken, getToken, localhostToken } from "./utilities/common";
import { getBaseURL } from "./redux/actions";
import { jwtDecode } from "jwt-decode";

function App({ theme }) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const { auth, setAuth } = useAuth();
    const refresh = useRefreshToken();
    const [token] = useState(getToken());
    const getPosition = (token, index) => {
        return token.split("/", index).join("/").length;
    };

    useEffect(() => {
        // eerste keer bij landing
        if (!getRefreshToken()) {
            const params = window.location;
            const tokens = params.pathname.substring(7);
            const accessToken = tokens.substring(0, getPosition(tokens, 1));
            const refreshToken = tokens.substring(getPosition(tokens, 1) + 1, getPosition(tokens, 2));
            const license = tokens.substring(getPosition(tokens, 2) + 1, getPosition(tokens, 3));

            try {
                // controleer of url bevat een gelidige jwt die bestaat uit 3 gedeelte bijv. 'eyJ0eXAiOiJKV1Qi........'
                if (jwtDecode(accessToken)) {
                    license && sessionStorage.setItem("app_user_license", license);

                    // voor development
                    if (process.env.REACT_APP_API_URL === undefined) {
                        // gebruikt locale token in constante als dit niet in url zit
                        if (accessToken !== "" && refreshToken !== "") {
                            setAuth({
                                access_token: accessToken,
                                refresh_token: refreshToken,
                            });
                            sessionStorage.setItem(
                                "app_oauth",
                                JSON.stringify({
                                    access_token: accessToken,
                                    refresh_token: refreshToken,
                                }),
                            );
                        } else {
                            if (!token) {
                                setAuth(localhostToken);
                                sessionStorage.setItem("app_oauth", JSON.stringify(localhostToken));
                            }
                        }
                    } else {
                        // haal de params uit de url en opslaan in de sessionStorage
                        setAuth({ access_token: accessToken, refresh_token: refreshToken });
                        sessionStorage.setItem(
                            "app_oauth",
                            JSON.stringify({
                                access_token: accessToken,
                                refresh_token: refreshToken,
                            }),
                        );
                    }
                }
            } catch (error) {
                // bij ongeldige tokens redirecten naar inlogscherm
                // eslint-disable-next-line more/no-then
                getBaseURL("", "APM").then((base) => {
                    // redirect naar protserver
                    window.location.href = `${base.frontend_url}`;
                });
            } finally {
                if (auth) {
                    setIsLoading(false);
                }
            }
        } // bij browser refreshen
        else {
            // controleer of de jwt nog geldig is; is sessie nog actief
            // als dit wel het geval is settings ophalen, gebruikers opnieuw laten inloggen
            // eslint-disable-next-line more/no-then
            refresh()
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setError(true);
                });
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet titleTemplate="%s | Licentiebeheer" defaultTitle="Licentiebeheer" />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
                        <ThemeProvider theme={maTheme[theme.currentTheme]}>
                            <UseInterceptors>
                                <Snackbars />
                                {isLoading ? (
                                    <AuthLayout>
                                        {!error ? (
                                            <Box>
                                                <CircularProgress /> <p>Loading...</p>
                                            </Box>
                                        ) : (
                                            <RenewSession />
                                        )}
                                    </AuthLayout>
                                ) : (
                                    <Routes />
                                )}
                            </UseInterceptors>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
