// Snackbar
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const FINISHED_MESSAGE = "FINISHED_MESSAGE";
// Licensees
export const CREATE_LICENSEES = "CREATE_LICENSEES";
export const UPDATE_LICENSEES = "UPDATE_LICENSEES";
export const DELETE_LICENSEES = "DELETE_LICENSEES";
export const UPSERT_LICENSEES = "UPSERT_LICENSEES";
export const RESET_LICENSEES = "RESET_LICENSEES";
// Licenses
export const CREATE_LICENSES = "CREATE_LICENSES";
export const UPDATE_LICENSES = "UPDATE_LICENSES";
export const DELETE_LICENSES = "DELETE_LICENSES";
export const UPSERT_LICENSES = "UPSERT_LICENSES";
export const RESET_LICENSES = "RESET_LICENSES";
// Licversions
export const CREATE_LICVERSIONS = "CREATE_LICVERSIONS";
export const UPDATE_LICVERSIONS = "UPDATE_LICVERSIONS";
export const DELETE_LICVERSIONS = "DELETE_LICVERSIONS";
export const UPSERT_LICVERSIONS = "UPSERT_LICVERSIONS";
export const RESET_LICVERSIONS = "RESET_LICVERSIONS";
// Defmodulegroups
export const CREATE_DEFMODULEGROUPS = "CREATE_DEFMODULEGROUPS";
export const UPDATE_DEFMODULEGROUPS = "UPDATE_DEFMODULEGROUPS";
export const DELETE_DEFMODULEGROUPS = "DELETE_DEFMODULEGROUPS";
export const UPSERT_DEFMODULEGROUPS = "UPSERT_DEFMODULEGROUPS";
export const RESET_DEFMODULEGROUPS = "RESET_DEFMODULEGROUPS";
// Defapps
export const CREATE_DEFAPPS = "CREATE_DEFAPPS";
export const UPDATE_DEFAPPS = "UPDATE_DEFAPPS";
export const DELETE_DEFAPPS = "DELETE_DEFAPPS";
export const UPSERT_DEFAPPS = "UPSERT_DEFAPPS";
export const RESET_DEFAPPS = "RESET_DEFAPPS";
// Modules
export const CREATE_MODULES = "CREATE_MODULES";
export const UPDATE_MODULES = "UPDATE_MODULES";
export const DELETE_MODULES = "DELETE_MODULES";
export const UPSERT_MODULES = "UPSERT_MODULES";
export const RESET_MODULES = "RESET_MODULES";
// LicenseModules
export const UPDATE_LICENSEMODULES = "UPDATE_LICENSEMODULES";
export const DELETE_LICENSEMODULES = "DELETE_LICENSEMODULES";
export const UPSERT_LICENSEMODULES = "UPSERT_LICENSEMODULES";
export const RESET_LICENSEMODULES = "RESET_LICENSEMODULES";
// LicenseTypes
export const UPDATE_LICENSETYPES = "UPDATE_LICENSETYPES";
export const DELETE_LICENSETYPES = "DELETE_LICENSETYPES";
export const UPSERT_LICENSETYPES = "UPSERT_LICENSETYPES";
export const RESET_LICENSETYPES = "RESET_LICENSETYPES";
// Defversiontypes
export const CREATE_DEFVERSIONTYPES = "CREATE_DEFVERSIONTYPES";
export const UPDATE_DEFVERSIONTYPES = "UPDATE_DEFVERSIONTYPES";
export const DELETE_DEFVERSIONTYPES = "DELETE_DEFVERSIONTYPES";
export const UPSERT_DEFVERSIONTYPES = "UPSERT_DEFVERSIONTYPES";
export const RESET_DEFVERSIONTYPES = "RESET_DEFVERSIONTYPES";
// Admins
export const CREATE_ADMIN = "CREATE_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const UPSERT_ADMIN = "UPSERT_ADMIN";
export const RESET_ADMIN = "RESET_ADMIN";
// Defoptions
export const CREATE_DEFOPTIONS = "CREATE_DEFOPTIONS";
export const UPDATE_DEFOPTIONS = "UPDATE_DEFOPTIONS";
export const DELETE_DEFOPTIONS = "DELETE_DEFOPTIONS";
export const UPSERT_DEFOPTIONS = "UPSERT_DEFOPTIONS";
export const RESET_DEFOPTIONS = "RESET_DEFOPTIONS";

// Auth Logout
export const LOGOUT = "LOGOUT";
