import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  home,
  landing,
  newsession,
} from "./routes";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../components/standardComponents/Page404";
import { useAuth } from "../auth/AuthProvider";

// PublicRoutes token niet nodig om bij de pagina te komen
const publicRoutes = (Layout, routes) =>
  routes.map(({ path, component: Component }, index) => (
    // Route item without children
    <Route
      key={index}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

// PrivateRoutes token nodig om bij de pagina te komen
const privateRoutes = (Layout, routes) =>
  routes.map(({ path, component: Component }, index) => {
    const { auth } = useAuth();
   return <Route
      key={index}
      path={path}
      //exact
      render={(props) =>
        auth.access_token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
    });

const Routes = () => (
  <Router>
    <Switch>
      {privateRoutes(DashboardLayout, dashboardRoutes)}
      {publicRoutes(AuthLayout, home)}
      {publicRoutes(AuthLayout, landing)}
      {publicRoutes(AuthLayout, newsession)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;

