import Axios from "axios";
import { getURL } from "../../utilities/common";

export const getBaseURL = async (type, app) => {
    try {
        const baseResponse = await Axios.get(
            `${getURL("licenses").substring(0, getURL("licenses").length - 9)}baseurl`,
            {
                params: { app: app ?? "APM" },
            },
        );

        // indien app niet leeg  geeft de response url terug anders altijd als default de protserver
        if (app !== undefined) {
            return baseResponse.data;
        } else {
            if (type === "frontend") {
                // voor development -- in config aanpassen om dit niet leeg te krijgen
                if (baseResponse.data.value === "") {
                    return `http://${process.env.REACT_APP_LOCALIPADDRESS || "127.0.0.1"}:3000/`;
                } else {
                    return baseResponse.data.frontend_url;
                }
            } else {
                // voor development -- in config aanpassen om dit niet leeg te krijgen
                if (baseResponse.data.value === "") {
                    return `http://${process.env.REACT_APP_LOCALIPADDRESS || "127.0.0.1"}:9999/protserver/`;
                } else {
                    return `${baseResponse.data.backend_url}`;
                }
            }
        }
    } catch (error) {
        //do nothing
    }
};
