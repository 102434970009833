import Axios from "axios";
import { getBaseURL } from "../redux/actions";
import { LOGOUT } from "../redux/constants";
import { useAuth } from "./AuthProvider";
import { useDispatch } from "react-redux";

const useLogout = () => {
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const clearStorage = (base) => {
        dispatch({
            type: LOGOUT,
        });
        // clear localforage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        //redirect gebruiker naar login
        window.location.href = `${base.frontend_url}`;
    };

    const logout = async () => {
        const base = await getBaseURL("", "APM");

        try {
            await Axios.post(
                `${base.backend_url}logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${auth.access_token}`,
                        "Cache-Control": "no-cache",
                        Pragma: "no-cache",
                        Expires: "0",
                    },
                },
            );

            clearStorage(base);
        } catch (error) {
            clearStorage(base);
        }
    };

    return logout;
};
export default useLogout;
