import { Model, attr } from "redux-orm";
import {
    CREATE_LICVERSIONS,
    UPDATE_LICVERSIONS,
    DELETE_LICVERSIONS,
    UPSERT_LICVERSIONS,
    RESET_LICVERSIONS,
} from "../constants";

class Licversions extends Model {
    static reducer(action, Licversion, session) {
        switch (action.type) {
            case CREATE_LICVERSIONS:
                Licversion.create(action.payload);
                break;
            case UPSERT_LICVERSIONS:
                action.payload.forEach((licensedetail) => {
                    Licversion.upsert(licensedetail);
                });
                break;
            case UPDATE_LICVERSIONS:
                Licversion.withId(action.payload.licversionsid).update(action.payload);
                break;
            case DELETE_LICVERSIONS:
                const licversion = Licversion.withId(action.payload);
                licversion.delete();
                break;
            case RESET_LICVERSIONS:
                Licversion.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Licversions.modelName = "Licversions";

Licversions.options = {
    idAttribute: "licversionsid", // default idd
};
// attributen
Licversions.fields = {
    $id: attr(),
    licversionsid: attr(),
    licensesid: attr(),
    code: attr(),
    description: attr(),
    tenantid: attr(),
    deleted: attr(),
    lastlogin: attr(),
    licapps: attr(),
};

export default Licversions;
