import {
    CREATE_DEFVERSIONTYPES,
    DELETE_DEFVERSIONTYPES,
    UPDATE_DEFVERSIONTYPES,
    SUCCESS,
    UPSERT_DEFVERSIONTYPES,
} from "../constants";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

// haal alle types op
export const fetchDefVersionTypes = (id) => async (dispatch) => {
    try {
        const response = await api.get(`definitions/deflictypes`, {
            defappsid: id,
        });

        dispatch({
            type: UPSERT_DEFVERSIONTYPES,
            payload: response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// type toevoegen
export const createDefVersionType = (type) => async (dispatch) => {
    try {
        const createResponse = await api.post(`definitions/deflictypes`, type);

        const fetchResponse = await api.get(`definitions/deflictypes`, {
            defappsid: type.defappsid,
        });

        const newType = fetchResponse.data.value.find((app) => app.deflictypesid === createResponse.data.value);

        dispatch({
            type: CREATE_DEFVERSIONTYPES,
            payload: newType,
        });
        // // toon succesmelding
        // dispatch({
        //     type: SUCCESS,
        //     payload: " Type toegevoegd " + createResponse.data.value,
        // });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// type wijzigen
export const updateDefVersionType = (type) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/deflictypes`, type);

        const fetchResponse = await api.get(`definitions/deflictypes`, {
            defappsid: type.defappsid,
        });

        const updatedType = fetchResponse.data.value.find((app) => app.deflictypesid === updateResponse.data.value);

        dispatch({
            type: UPDATE_DEFVERSIONTYPES,
            payload: updatedType,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " Type gewijzigd " + updateResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

//type verwijderen
export const deleteDefVersionType = (id) => async (dispatch) => {
    try {
        const response = await api.delete(`definitions/deflictypes`, {
            deflictypesid: id,
        });

        dispatch({
            type: DELETE_DEFVERSIONTYPES,
            payload: id,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " Type verwijderd " + response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
