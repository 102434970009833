import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import { AuthProvider } from "./auth/AuthProvider";

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
