import Axios from "axios";
import { localhostUrl } from "../utilities/common";

const BASE_URL = `${process.env.REACT_APP_API_URL || localhostUrl}`;

// maak een instantie van Axios
export const axiosInstance = Axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  // `withCredentials` indicates whether or not cross-site Access-Control requests
  // should be made using credentials
  //  withCredentials: true,
});

// get
const getRequest = async (endpoint, params) => {
  const response = await axiosInstance.get(endpoint, {
    params: params,
    //  withCredentials: true,
  });

  return response;
};

// post
const postRequest = (endpoint, params) => {
  return axiosInstance.post(endpoint, params);
};

// delete
const deleteRequest = (endpoint, params) => {
  return axiosInstance.delete(endpoint, {
    params: params,
  });
};

const apiService = {
  get: getRequest,
  post: postRequest,
  delete: deleteRequest,
};
export default apiService;
