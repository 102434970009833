import { Model, attr } from "redux-orm";
import {
    CREATE_DEFVERSIONTYPES,
    UPDATE_DEFVERSIONTYPES,
    DELETE_DEFVERSIONTYPES,
    UPSERT_DEFVERSIONTYPES,
    RESET_DEFVERSIONTYPES,
} from "../constants";

class Defversiontypes extends Model {
    static reducer(action, Defversiontypes, session) {
        switch (action.type) {
            case CREATE_DEFVERSIONTYPES:
                Defversiontypes.create(action.payload);
                break;
            case UPSERT_DEFVERSIONTYPES:
                action.payload.forEach((versiontypes) => {
                    Defversiontypes.upsert(versiontypes);
                });
                break;
            case UPDATE_DEFVERSIONTYPES:
                Defversiontypes.withId(action.payload.deflictypesid).update(action.payload);
                break;
            case DELETE_DEFVERSIONTYPES:
                const defversiontypes = Defversiontypes.withId(action.payload);
                defversiontypes.delete();
                break;
            case RESET_DEFVERSIONTYPES:
                Defversiontypes.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Defversiontypes.modelName = "Defversiontypes";

Defversiontypes.options = {
    idAttribute: "deflictypesid", // default idd
};
// attributen
Defversiontypes.fields = {
    $id: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
    deflictypesid: attr(),
    defmodules: attr(),
};

export default Defversiontypes;
