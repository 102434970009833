import { Model, attr } from "redux-orm";
import {
    CREATE_DEFMODULEGROUPS,
    UPDATE_DEFMODULEGROUPS,
    DELETE_DEFMODULEGROUPS,
    UPSERT_DEFMODULEGROUPS,
    RESET_DEFMODULEGROUPS,
} from "../constants";

class Defmodulegroups extends Model {
    static reducer(action, Defmodulegroups, session) {
        switch (action.type) {
            case CREATE_DEFMODULEGROUPS:
                Defmodulegroups.create(action.payload);
                break;
            case UPSERT_DEFMODULEGROUPS:
                action.payload.forEach((element) => {
                    Defmodulegroups.upsert(element);
                });

                break;
            case UPDATE_DEFMODULEGROUPS:
                Defmodulegroups.withId(action.payload.defmodulegroupsid).update(action.payload);
                break;
            case DELETE_DEFMODULEGROUPS:
                const defmodulegroups = Defmodulegroups.withId(action.payload);
                defmodulegroups.delete();
                break;
            case RESET_DEFMODULEGROUPS:
                Defmodulegroups.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
Defmodulegroups.modelName = "Defmodulegroups";

Defmodulegroups.options = {
    idAttribute: "defmodulegroupsid", // default idd
};
// attributen
Defmodulegroups.fields = {
    $id: attr(),
    defmodulegroupsid: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
    defModules: attr(),
};

export default Defmodulegroups;
