import { Model, attr } from "redux-orm";
import { UPDATE_LICENSETYPES, DELETE_LICENSETYPES, UPSERT_LICENSETYPES, RESET_LICENSETYPES } from "../constants";

class LicenseTypes extends Model {
    static reducer(action, LicenseTypes, session) {
        switch (action.type) {
            case UPSERT_LICENSETYPES:
                action.payload.forEach((type) => {
                    LicenseTypes.upsert({
                        ...type,
                        uid: Number(String(type.defappsid) + String(type.deflictypesid)),
                        licensesid: action.licensesid,
                    });
                });
                break;
            case UPDATE_LICENSETYPES:
                LicenseTypes.withId(action.payload.deflictypesid).update(action.payload);
                break;
            case DELETE_LICENSETYPES:
                const licensetypes = LicenseTypes.withId(action.payload);
                licensetypes.delete();
                break;
            case RESET_LICENSETYPES:
                LicenseTypes.delete();
                break;
            default:
                break;
        }
        // Return value is ignored.
        //return undefined;
    }
}
LicenseTypes.modelName = "LicenseTypes";

LicenseTypes.options = {
    idAttribute: "deflictypesid", // default idd
};
// attributen
LicenseTypes.fields = {
    uid: attr(),
    licensesid: attr(),
    $id: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
    deflictypesid: attr(),
};

export default LicenseTypes;
