
import async from "../components/standardComponents/Async";
import { BarChart2, Clipboard, Grid } from "react-feather";

const modulesPage = async(() => import("../components/definitions/index"));
const licensePage = async(() => import("../components/licenses/main/License"));
const detailsPage = async(() => import("../components/licenses/details/index"));
const dashboardPage = async(() => import("../components/dashboard/Dashboard"));
const landingPage = async(() => import("../components/home/LandingPage"));
const homePage = async(() => import("../components/home/DefaultHome"));
const page404 = async(() => import("../components/standardComponents/Page404"));
const pageRenew = async(() => import("../auth/RenewSession"));

const RenewRoutes = {
    id: "Renew",
    path: "/renewsession",
    component: pageRenew,
    name: "Renew",
    icon: null,
    children: null,
};
//Sidenav componenten
const DashboardRoutes = {
    id: "Dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: <BarChart2 />,
    //containsHome: true,
    component: dashboardPage,
    children: null,
};
const LandingRoutes = {
    id: "Landing",
    path: "/login/:accessToken/:refreshToken/:licensesid",
    component: landingPage,
    name: "Landing",
    //containsHome: true,
    children: null,
};
const HomeRoutes = {
    id: "Home",
    path: "/",
    component: homePage,
    name: "Home",
    containsHome: true,
    children: null,
};
const Page404Routes = {
    id: "404",
    path: "/404",
    name: "NotFound",
    component: page404,
    children: null,
};

const LicentieDetails = {
    id: "Details",
    path: "/license_details/:holder/:name/:id",
    component: detailsPage,
    children: null,
};

const LicentieRoutes = {
    id: "Licenties",
    path: "/licenses",
    //name: "License",
    icon: <Clipboard />,
    component: licensePage,
    children: null,
};
const DefinitieRoutes = {
    id: "Modules",
    path: "/modules",
    //name: "Modules",
    icon: <Grid />,
    component: modulesPage,
    children: null,
};

export const PageNotFound = [Page404Routes];

export const dashboard = [
    DashboardRoutes,
    LicentieRoutes,
    DefinitieRoutes,
    LicentieDetails,
    // HomeRoutes,
];
export const home = [HomeRoutes];
export const landing = [LandingRoutes];
export const newsession = [RenewRoutes];

const routes = [DashboardRoutes, LicentieRoutes, DefinitieRoutes];
export default routes;
