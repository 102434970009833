import { SUCCESS, ERROR, FINISHED_MESSAGE } from "../constants";

export default function reducer(state = [], action) {
    switch (action.type) {
        case SUCCESS:
            return [...state, { message: action.payload, type: "success" }];
        case ERROR:
            return [...state, { message: action.payload, type: "error" }];
        case FINISHED_MESSAGE:
            const [, ...newState] = state;
            return newState;
        default:
    }
    return state;
}
