import { useSelector, connect } from "react-redux";
import { currentMessage } from "../../redux/selectors/snackbarSelector";
import { finishMessage } from "../../redux/actions/snackbarActions";
/*
Dit is de Snackbarmelding component. Op deze pagina wordt bepaald of een melding een succes of error melding is. 
als de melding een error is wordt de snackbar rood en als de melding succes is wordt de snackbar groen.
*/

import { IconButton, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Close } from "@material-ui/icons";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snackbars(props) {
    const message = useSelector(currentMessage);

    const handleAlertClose = (event, reason) => {
        props.finishMessage();
    };
    return message ? (
        /*
    snackbar om succes- of foutmelding te geven. 
    als type is error duur is 6 seconde & kleur is rood.
    als type is succes duur is 2 seconde & kleur is groen.
    */
        <Snackbar open={true} autoHideDuration={message.type === "error" ? 6000 : 2000} onClose={handleAlertClose}>
            {message.type === "error" ? (
                <Alert
                    variant="filled"
                    severity="error"
                    style={{ backgroundColor: "#A70C00" }}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleAlertClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    }
                >
                    {message.message}
                </Alert>
            ) : (
                <Alert
                    severity="success"
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleAlertClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    }
                >
                    {message.message}
                </Alert>
            )}
        </Snackbar>
    ) : null;
}

const mapDispatchToProps = {
    finishMessage,
};

export default connect(null, mapDispatchToProps)(Snackbars);
