import { DELETE_LICVERSIONS, SUCCESS, UPSERT_LICVERSIONS } from "../constants";
import { getSearchOption } from "../../utilities/common";
import { fetchLicenses } from "./licensesActions";
import { updateLicapps } from "./licappsActions";
import { handleErrors } from "../../components/shared/helpers";
import api from "../../api/apiService";

let search = window.location.search.substring(window.location.search.indexOf("=") + 1, window.location.search.length);
// haal alle licentie versies op
export const fetchLicenseVersions = (id) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/licversions`, {
            //deleted: 1 alleen verwijderd, 2 alles, 0 alleen actief
            licensesid: id,
            Deleted: 2,
        });

        dispatch({
            type: UPSERT_LICVERSIONS,
            payload: response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
// licentie versies verwijderen
export const deleteLicenseVersion = (licversionid, license, deleted) => async (dispatch) => {
    try {
        const deleteResponse = await api.delete(`licenses/licversions`, {
            licversionsid: licversionid,
            permanently: deleted,
        });
        // verwijderd alleen de geselecteerde profiel in de orm
        dispatch({
            type: DELETE_LICVERSIONS,
            payload: licversionid,
        });
        dispatch(fetchLicenseVersions(license));
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Versie verwijderd " + licversionid + deleteResponse.data,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentie versies toevoegen
export const createLicenseVersion = (items, apps) => async (dispatch) => {
    let defSearch;
        if (search === "empty") {
            defSearch = "";
        } else {
            defSearch = search;
        }
    try {
        const createResponse = await api.post(`licenses/licversions`, items);

        const licversion = createResponse.data.value;
        // vraag alle defapps
        const defapps = await api.get(`definitions/defapps`);

        // zoek de defappsid van applicatiebeheer
        const APM = defapps.data.value?.find((a) => a.code === "APM")?.defappsid;
        //alle geselecteerde app toevoegen aan de licentie versie
        await apps.forEach((app, index) => {
            // app die toegevoegd moet worden
            const selectedApp = Number(app.substring(0, app.indexOf(",")));
            // is de app applicatiebeheer
            const isAPM = selectedApp === APM;

            dispatch(
                updateLicapps({
                    licappsid: 0,
                    licensesid: items.licensesid,
                    // APM  mag niet aan de versie hangen
                    licversionsid: isAPM ? 0 : licversion,
                    defappsid: selectedApp,
                    // dbname: DATABASE,
                    activationdate: "",
                    exptestdate: "",
                    expdate: "",
                    deleted: "",
                }),
            );
            // haal bij de laatste app alle licenties en geselecteerde licentieversies op
            if (index === apps.length - 1) {
                dispatch(fetchLicenses(defSearch, Number(getSearchOption())));
                //dispatch(fetchLicenseVersions(items.licensesid));
            }
        });
        // // toon succesmelding
        // dispatch({
        //     type: SUCCESS,
        //     payload: "Versie toegevoegd " + createResponse.data.value,
        // });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentie versies wijzigen
export const updateLicenseVersion = (items) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`licenses/licversions`, items);
        dispatch({
            type: SUCCESS,
            payload: "Versie gewijzigd " + updateResponse.data.value,
        });
        // haal alle versies bij specifieke licentie op
        dispatch(fetchLicenseVersions(items.licensesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
